import React, { Component } from "react";
import { CSVLink } from "react-csv";
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
//import _ from "lodash";
//import { makeData, Logo, Tips } from "./Utils";
import Sidebar from "../layout/sidebar";

//import AddLearnerComponent from "../add-learner-user/AddLearnerUser"

//import EditLearnerComponent from "../edit-learner-user/EditLearnerUser"

import {
  sendFlashMessage,
  clearcurrentFlashMessage
} from "../../actions/flashMessage";
import Flashmessages from "../flashMessagesComp/flashMessages";

import Pagination from "../common/Pagination";

import "./paccsetting.css";

//const rawData = makeData();

class ShowJobId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      ljobdtall: [],
      loading: false,
      pages: null,
      setjobdataloading: true,
      pageSize: null,
      sorted: []
    };

    this.setLatestJobData = this.setLatestJobData.bind(this);

    this.reactContactTable = React.createRef();
  }

  componentDidMount() {
    console.log("jobid ", this.props.match.params.jobid);

    const currentreactcontactTable = this.reactContactTable.current;
    if (currentreactcontactTable) {
      const page = currentreactcontactTable.state.page;
      const pageSize = currentreactcontactTable.state.pageSize;
      console.log("from compdidmount page is : " + page);
      console.log("from compdidmount pageSize is : " + pageSize);
      const currentrctstatusobj = {
        page: page,
        pageSize: pageSize
      };
      this.setLatestJobData(currentrctstatusobj);
    }
  }

  setLatestJobData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `setjobdataloading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ setjobdataloading: true });

    console.log("pageSize value is : " + state.pageSize); //10 data per page as we defined
    console.log("page value is : " + state.page); //current request page //at starting its 0 value for page
    // console.log("sorted value is : "+state.sorted)
    //console.log("filtered value is : "+state.filtered)

    const requestData = (pageSize, page, sorted, filtered) => {
      // console.log("Fetch data: sorted -> ", JSON.stringify(sorted));
      return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        //  let filteredData = rawData; //array of objects (random generated local fake data)

        let jobid = this.props.match.params.jobid;

        axios
          .get(
            `/api/v1/batch/jobErrors?jobId=${jobid}&size=${pageSize}&page=${page}`
          )
          .then(res => {
            var filteredData = res.data.content;

            // this.setState({pageSize:res.data.pageable.pageSize});
            //this.setState({ ljobdtall: res.data.content });
            //console.log("filteredData is below: ")
            //  console.log(filteredData)

            // You must return an object containing the rows of the current page, and optionally the total pages number.
            const fres = {
              //  rows: sortedData.slice(pageSize * page, pageSize * page + pageSize),
              // pages: Math.ceil(filteredData.length / pageSize)
              rows: filteredData,
              pages: res.data.totalPages
            };

            // Here we'll simulate a server response with 500ms of delay.
            setTimeout(() => resolve(fres), 500);

            //this.setState({loading:false})
          })
          .catch(err => {
            this.setState({ setjobdataloading: false });
            console.log("job request data is not getting !!");
            console.log("job request error is : " + err);
            // alert("Something went wrong!!")
          });
      });
    };

    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(state.pageSize, state.page, state.sorted, state.filtered).then(
      res => {
        // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
        if (res) {
          this.setState({
            ljobdtall: res.rows,
            pages: res.pages,
            setjobdataloading: false
          });
        } else {
          console.log("application request data is not getting !!");
        }
      }
    );
  }

  _handlecomingsoon = () => {
    alert("Feature is coming soon !!");
  };

  render() {
    const { ljobdtall, setjobdataloading, pages } = this.state;

    let columns = [
      {
        Header: "Job id",
        accessor: "jobInstanceId",
        maxWidth: 100,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "Error Message",
        accessor: "rawErrorMessages",
        maxWidth: 200,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "firstname",
        accessor: "firstname",
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "wcea license number",
        accessor: "wcea_license_number",
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "wcea index number",
        accessor: "wcea_index_number",
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "wcea license number",
        accessor: "wcea_license_number",
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "email",
        accessor: "email",
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "wcea organistion uid",
        accessor: "wcea_organistion_uid",
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      }
    ];

    let csvContent;
    let csvheaders = [
      { label: "jobInstanceId", key: "jobInstanceId" },
      { label: "First Name", key: "firstname" },
      { label: "Lastname", key: "lastname" },
      { label: "Wcea License Number", key: "wcea_license_number" },
      { label: "Mobile", key: "mobile" },
      { label: "Email", key: "email" },
      { label: "Wcea Organistion Uid", key: "wcea_organistion_uid" },
      { label: "Error Messages", key: "errorMessages" }
    ];

    if (ljobdtall && ljobdtall.length > 0) {
      csvContent = (
        <CSVLink
          data={ljobdtall}
          headers={csvheaders}
          filename={"Job-id-rejected-data.csv"}
          className="btn btn-sm btn-success"
        >
          Export CSV
        </CSVLink>
      );
    }

    return (
      <div>
        <Sidebar />
        <div id="wrapper">
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to="/view-jobs-id">View Jobs Id</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {this.props.match.params.jobid}
                      </li>
                    </ol>
                  </div>
                  <div className="col-sm-6">
                    <div className="float-sm-right">
                      {csvContent && csvContent}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section className="content">
              <Flashmessages />

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      {/*ljobdtallCont&&ljobdtallCont*/}
                      <ReactTable
                        PaginationComponent={Pagination}
                        ref={this.reactContactTable}
                        data={ljobdtall}
                        columns={columns}
                        defaultPageSize={5}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        pages={pages} // Display the total number of pages
                        loading={setjobdataloading} // Display the loading overlay when we need it
                        onFetchData={this.setLatestJobData} // Request new data when things change
                        sorted={this.state.sorted}
                        onSortedChange={(newSort, column) => {
                          this.setState({ sorted: newSort });
                        }}
                        //  showPaginationTop={false}
                        //  showPaginationBottom={true}

                        /* pageSizeOptions={[
                      5,
                      10,
                      15,
                      20,
                      25,
                      30,
                      35,
                      40,
                      50
                    ]}*/
                        style={
                          {
                            //  height: "600px" // This will force the table body to overflow and scroll, since there is not enough room
                            /*, maxWidth: "800px" */
                          }
                        }
                        className="-striped -highlight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

ShowJobId.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage
})(withRouter(ShowJobId));
