import React, { Component } from "react";
import PropTypes from "prop-types";
 
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";    

import axios from "axios";
import queryString from 'query-string'

import "./css/Loginstyl.css"

import DefaultBackgroundbranding from "./img/branding2.jpg" 
import Flashmessages from "../flashMessagesComp/flashMessages"
 //import FlashMessagesList from "../flash/FlashMessagesList"

//import fire from '../../config/Fire';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      councillogo:"",
      Backgroundbranding:"",
      username: "",
      password: "",
      errors: {},
      loading:false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
 

  }
  componentDidMount() {
     
  //if isAuthenticated is TRUE so redirect the user to /dashboard
  if (this.props.auth.isAuthenticated) {
   // console.log("this.props.auth.isAuthenticated is : "+this.props.auth.isAuthenticated)
    this.props.history.push("/dashboard");
  } 
  
    if(this.props.location.search){
      console.log("query params are : "+this.props.location.search)

      const values = queryString.parse(this.props.location.search)
  
      let cid=values.councilId 
     // console.log(values.email) // "im"
  
  
      //let cid=this.props.match.params.councilId
     
  
      console.log("councilId is : "+cid) // eg:"20"
  
      this.setState({loading:true})
       
      axios.get(`/p/councils/${cid}/logo`).then(res => {
        
  // console.log("final council logo received is below : ")
  // console.log(res.data)
       // this.setState({councillogo:res.data})
        //this.setState({councillogo:res.data[0].url})
        this.setState({Backgroundbranding:res.data.userProperties.backgroundImg})  
       
        this.setState({loading:false})
             }).catch(err=>{
           console.log("get council logo with background img error is : "+err)
            
        this.setState({loading:false})
          // alert("Something went wrong!!")
         })
  
    }
 
        
  }
 
  
  onSubmit(e) {
    e.preventDefault();

    const userData = {
      username: this.state.username,
      password: this.state.password
    };
 
    if (userData.username === "") {
      alert("Username Field cannot be empty!!");
    }else if(userData.password===""){
      alert("Password Field cannot be empty!!");
  
    }
    //  console.log(user);
    
      if(userData.username &&userData.password){
        this.props.loginUser(userData,this.props.history); //here we call the loginUser action
    
      // alert("Hit Login Api Soon !!")
      }
    }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
 
  setFlashmessage1=()=>{
    console.log("set flash message")
    this.props.addFlashMessage({
      type: 'success',
      text: 'You signed up successfully. Welcome!'
    });
  }

  setFlashmessage2=()=>{
    console.log("set flash message")
    this.props.addFlashMessage({
      type: 'error',
      text: 'You signed up successfully. Welcome!'
    });
  }

  render() {
    const { errors,loading,Backgroundbranding } = this.state; //the errors state is set in componentWillReceiveProps from the redux error reducer at store and used in this Login Component
 
    const {userloginloading}=this.props.auth

    let bgbrand=Backgroundbranding?Backgroundbranding:DefaultBackgroundbranding

    return (
        <div hidden = {loading?'hidden':''}
          className="login"
          style={{
            backgroundImage: `url(${bgbrand})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat', 
          //  paddingBottom: 50,
          // paddingTop: 50
          }}
        >
        <div className="col-md-12" style={{paddingLeft:"7%"}}>
        
        <Flashmessages />
        </div>
 

        <div className="center-div">
        <div  className="card cardloginbody">
  
            <div className="card-body"> 
                
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                <div className="input-container">
                <i className="fa fa-user usericon"></i>
                <input className="input-field"
                placeholder="User Name"
                name="username"
                type="text"
                value={this.state.username}
                onChange={this.onChange}
                error={errors.username}/>
              </div>
                    
                </div>
  
                <div className="form-group">
                  
                  <div className="input-container">
                  <i className="fa fa-key usericon"></i>
                  <input className="input-field" 
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange} />
                </div>
                </div>


  
  
                <div className="form-row">
            {/*}    <div className="form-group col-md-12" style={{textAlign:"right"}}>
                  <Link
                    to="/forgot-password"
                    style={{ color: "grey" }}
                  >
                    Forgot Password?
                  </Link>
        </div>*/}
              </div>
  
              
              <div className="form-row">
              <div className="form-group col-md-5 m-auto">
                {userloginloading?<button disabled className="btn btn-info btn-block mt-4 loginbtnbg">
                <i className="fa fa-spinner fa-spin"></i> Loading...
              </button>:
                <input
                  disabled={!this.state.username||!this.state.password}
                  type="submit"
                  value="Log in"
                  className="btn btn-info btn-block mt-4 loginbtnbg"
                 />  }
              </div>
            </div>
  
              {/*}   <button type="submit" onClick={this.login} className="btn btn-primary">Login</button>
                <button onClick={this.signup} style={{marginLeft: '25px'}} className="btn btn-success">Signup</button>
        */}
              
              </form>
  
          
            </div>
  
          </div>
      
        </div>
      
        </div>
    );
  }
}

Login.propTypes = {
  //map the property of Login component via propTypes
  loginUser: PropTypes.func.isRequired, //loginUser is an action but also the property of this component
  auth: PropTypes.object.isRequired, //auth is also property of this component
  errors: PropTypes.object.isRequired, //errors is also property of this component   
   
};

const mapStateToProps = state => ({
  //here map the auth (state.auth) from the root reducer (from store.js) where in combineReducer auth : authReducer similarly for errors
  auth: state.auth,
  errors: state.errors,  
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
