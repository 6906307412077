import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";

import {
  // sendFlashMessage,
  clearcurrentFlashMessage
} from "../../actions/flashMessage";

import "./flashMessages.css";

class flashMessages extends Component {
  constructor() {
    super();
    this.state = {
      errors: {}
    };
  }
  componentDidMount() {}

  onclearflashmessageClick() {
    this.props.clearcurrentFlashMessage();
  }

  render() {
    const { message, className } = this.props.flashMessage;

    return message && className ? (
      <div className="flashMessages col-md-10">
        <br />
        <center>
          <div
            className={
              "col-md-6 alert" + " " + className + " " + "alert-dismissible"
            }
          >
            {/*}  <span
           onClick={this.onclearflashmessageClick.bind(this)}
           className="close"
           data-dismiss="alert"
           aria-label="close"
           style={{color:"darkblue"}}
         >
           &times;
        </span>*/}
            <strong> {message}</strong>
          </div>
        </center>
      </div>
    ) : (
      <div />
    );
  }
}

flashMessages.propTypes = {
  auth: PropTypes.object.isRequired, //auth is also property of this component
  flashMessage: PropTypes.object.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  //here map the auth (state.auth) from the root reducer (from store.js) where in combineReducer auth : authReducer similarly for errors
  auth: state.auth,
  errors: state.errors,
  flashMessage: state.flashMessage
});

export default connect(mapStateToProps, {
  loginUser,
  clearcurrentFlashMessage
})(flashMessages);
