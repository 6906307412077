import React, { Component } from "react";
import "react-table/react-table.css";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Select from "react-select";
import Spinner from "../common/Spinner";

import Sidebar from "../layout/sidebar";
import {
  sendFlashMessage,
  clearcurrentFlashMessage
} from "../../actions/flashMessage";
import Flashmessages from "../flashMessagesComp/flashMessages";
import Progress from "../common/Progress";

class BulkuploadLearners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      assigngrpsmultiselectarr: [],
      newlearnerfile: "",
      onlygroups: null,
      groups: null,
      subgroupsall: [],
      finalassigngrpmultiselectarr: [],
      allgroupsarrobjs: null,
      csvwithgrouploading: false,
      uploadPercentage: 0,
      csvuploadsuccesstrackmsg: "",
      csvuploaderrors: null
    };

    this.onChange = this.onChange.bind(this);
    //this.onSubmit = this.onSubmit.bind(this);

    //this.onChangepassword = this.onChangepassword.bind(this);
  }

  componentDidMount() {
    //if isAuthenticated is not TRUE so redirect the user to /

    //signature response
    const csvreserrordata = [
      {
        errorMessages: [
          {
            type: "email",
            message: "not valid"
          },
          {
            type: "name",
            message: "cannot empty"
          }
        ],
        rowId: "1",
        rowData: "",
        step: "3",
        message: "Validation Errors"
      },
      {
        errorMessages: [
          {
            type: "email",
            message: "not valid"
          },
          {
            type: "name",
            message: "cannot empty"
          },
          {
            type: "mobile",
            message: "should not contain alphabets"
          }
        ],
        rowId: "2",
        rowData: "",
        step: "5",
        message: "Validation with DB Errors"
      }
    ];

    this.setState({ csvuploaderrors: csvreserrordata });

    //this.props.getCurrentAllStock();

    // Github fetch library : https://github.com/github/fetch
    // Call the API page
    /* fetch('https://www.reddit.com/r/reactjs.json')
   .then((result) => {
      // Get the result
      // If we want text, call result.text()
      return result.json();
    }).then((jsonResult) => {
      // Do something with the result
      console.log(jsonResult);
    })*/

    this._hitwceagroupsapi();
    //this.setLatestLearners()
  }

  _hitwceagroupsapi = () => {
    axios
      .get("/api/v1/wcea/groups")
      .then(res => {
        this.setState({ allgroupsarrobjs: res.data });

        var onlygroupsData = this.state.allgroupsarrobjs.filter(function(grp) {
          return grp.parentId === null;
        });

        console.log(onlygroupsData);
        this.setState({ onlygroups: onlygroupsData });

        /*   var onlysubgroupsData =  res.data.filter(function(grp) {
          return grp.parentId !== null;
        });
         
        console.log(onlysubgroupsData)

 this.setState({subgroupsall:onlysubgroupsData})*/
      })
      .catch(err => {
        console.log("error is : " + err);
        // alert("Something went wrong!!")
      });
  };

  onChange = e => {
    switch (e.target.name) {
      case "productImage":
        this.setState({ productImage: e.target.files[0] });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  _handlecomingsoon = () => {
    alert("Feature is coming soon !!");
  };

  onChangecsvfile = e => {
    switch (e.target.name) {
      case "newlearnerfile":
        this.setState({ newlearnerfile: e.target.files[0] });
        break;
      default:
        this.setState({ newlearnerfile: "no file" });
    }
  };

  _handlegroupingevent = (id, name) => {
    const { finalassigngrpmultiselectarr } = this.state;

    console.log("is is : " + id + " name is : " + name);

    //here we first check inside array of object if in any object
    //there id found so we will splice object otherwise we insert new object with id and name
    let idfound = true;
    for (var i = 0; i < finalassigngrpmultiselectarr.length; i++) {
      if (finalassigngrpmultiselectarr[i].id === id) {
        console.log("id found inside array of object");

        finalassigngrpmultiselectarr.splice(i, 1);

        idfound = false;
      }
    }

    if (idfound) {
      console.log("new entry");

      let newentry = {
        id: id,
        name: name
      };
      finalassigngrpmultiselectarr.unshift(newentry);
    }
  };

  /*
  handlefinalAssigngroup =()=>{
 
    const {finalmultiselected,finalassigngrpmultiselectarr} =this.state

    if(finalmultiselected.length){

      console.log("selected id array is below :")

      console.log(finalmultiselected)
  
      //finalmultiselected=>array of selected id
      //finalassigngrpmultiselectarr=>array of object of selected assign groups
      console.log("here we will assign group api hit")
  
      let finaldata=[]
  
      for(var i=0;i<finalmultiselected.length;i++){
        
      let middata={
        id:finalmultiselected[i],
        contactGroup:finalassigngrpmultiselectarr
      }
      finaldata.push(middata)
  
          }
  
          console.log(finaldata)
  
          var token = localStorage.getItem("jwtToken");
          //console.log("token is : "+token)
          var config = {
            headers: {'Authorization': `Bearer ${token}`}
        }; 
             axios.put("/api/contacts/groups",finaldata,config).then(res => {
               this.setState({finalmultiselected:[],finalassigngrpmultiselectarr:[],groups:"",subgroupsall:[]})
               this._hitwceagroupsapi()
               
       //this.hideAssigngroupModalClick()
            alert("Group Assign Successfully !!")
            }).catch(err=>{
              console.log("error is : "+err)
             // alert("Something went wrong!!")
            })
     
  
    }else{
      alert("First Select Contact Using Checkbox")
    }


  }*/

  handlegroupsChange = groups => {
    this.setState({ groups });
    console.log(`Option selected:`, groups);

    let id = groups.value;
    var onlysubgroupsData = this.state.allgroupsarrobjs.filter(function(grp) {
      return grp.parentId === id;
    });

    console.log(onlysubgroupsData);

    this.setState({ subgroupsall: onlysubgroupsData });

    // let groupid=groups.value
    //this.handlesetsubgroup(groupid)
  };

  handleBulkUploadCsvError = jsonData => {
    /*let jsonData=[{
    "error1":"message1",
    "error2":"message2",
  }]*/
    const fileData = JSON.stringify(jsonData);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "bulkupload-csv-errors.txt";
    link.href = url;
    link.click();
  };

  onSubmitbycsv = async e => {
    e.preventDefault();

    const { newlearnerfile, finalassigngrpmultiselectarr } = this.state;

    if (newlearnerfile) {
      if (finalassigngrpmultiselectarr.length) {
        console.log("selected group is below :");

        console.log(finalassigngrpmultiselectarr);

        //finalassigngrpmultiselectarr=>array of object of selected assign groups
        console.log("here we will assign group api hit");

        console.log("selected csv flle is below :");
        console.log(newlearnerfile);

        let newLearnerData = new FormData();
        newLearnerData.append("file", newlearnerfile);

        this.setState({ csvwithgrouploading: true });

        this.setState({
          csvuploadsuccesstrackmsg: "Please wait file is uploading..."
        });

        axios
          .post("/api/v1/uploads", newLearnerData, {
            onUploadProgress: progressEvent => {
              // console.log(progressEvent)
              this.setState({
                uploadPercentage: parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              });

              // Clear percentage
              setTimeout(() => this.setState({ uploadPercentage: 0 }), 2000);
            }
          })
          .then(res => {
            this.setState({
              csvuploadsuccesstrackmsg: "Please wait file data is processing..."
            });

            let filename = res.data;
            console.log("filename received which is : " + filename);

            let gString = finalassigngrpmultiselectarr
              .map(x => x.id)
              .toString();

            console.log("gString is : " + gString);

            let data = {
              assetId: filename,
              contactGroups: gString
            };

            axios
              .post(`/api/v1/batch/contacts/uploads`, data)
              .then(async res => {
                this.setState({
                  csvuploadsuccesstrackmsg:
                    "Your bulk csv upload success job id is : " + res.data
                });

                //  let  jobidresdata =  await axios.get(`/p/batch/errors/contactuploads?jobId=${res.data}`)

                //  if(jobidresdata){

                //    console.log('jobidresdata',jobidresdata.data);
                //  }else{
                //     console.log('jobidresdata is not received')
                //  }

                this.setState({ csvwithgrouploading: false });
                this.setState({
                  newlearnerfile: "",
                  finalassigngrpmultiselectarr: [],
                  groups: "",
                  subgroupsall: []
                });

                this.props.sendFlashMessage(
                  "The bulk upload request completed successfully !!",
                  "alert-success"
                );
                setTimeout(() => {
                  this.props.clearcurrentFlashMessage();
                }, 2000);

                //  alert("The bulk upload request completed successfully !!")
              })
              .catch(err => {
                let jsonData = err;
                this.setState({ csvwithgrouploading: false });
                this.setState({
                  newlearnerfile: "",
                  finalassigngrpmultiselectarr: [],
                  groups: "",
                  subgroupsall: [],
                  csvuploadsuccesstrackmsg: ""
                });

                //this.setState({csvuploaderrors:err.data})

                /*  let jsonData=[{
              "error1":"message1",
              "error2":"message2",
            }]*/
                if (err.response.status === 504) {
                  //The 504 Gateway Timeout error is an HTTP status code
                  // this.handleBulkUploadCsvError(jsonData);
                  // console.log("error is : " + err);
                  this.props.sendFlashMessage(
                    "Your upload file is in progress check back later !!",
                    "alert-info"
                  );
                  setTimeout(() => {
                    this.props.clearcurrentFlashMessage();
                  }, 2000);

                  //  alert("upload request has timed out with no response. Please try again or contact support")
                } else {
                  this.handleBulkUploadCsvError(jsonData);
                  console.log("error is : " + err);

                  this.props.sendFlashMessage(
                    "The bulk upload request completed with errors, please see the downloaded file for more details !!",
                    "alert-danger"
                  );
                  setTimeout(() => {
                    this.props.clearcurrentFlashMessage();
                  }, 2000);

                  // alert('The bulk upload request completed with errors, please see the downloaded file for more details')
                }
              });
          })
          .catch(err => {
            let jsonData = err;
            this.setState({ csvwithgrouploading: false });
            this.setState({
              newlearnerfile: "",
              finalassigngrpmultiselectarr: [],
              groups: "",
              subgroupsall: [],
              csvuploadsuccesstrackmsg: ""
            });

            //this.setState({csvuploaderrors:err.data})
            if (err.response.status === 504) {
              this.handleBulkUploadCsvError(jsonData);
              console.log("error is : " + err);

              this.props.sendFlashMessage(
                "upload request has timed out with no response. Please try again or contact support",
                "alert-danger"
              );
              setTimeout(() => {
                this.props.clearcurrentFlashMessage();
              }, 2000);

              // alert("upload request has timed out with no response. Please try again or contact support")
            } else {
              this.handleBulkUploadCsvError(jsonData);
              console.log("error is : " + err);

              this.props.sendFlashMessage(
                "The bulk upload request completed with errors, please see the downloaded file for more details",
                "alert-danger"
              );
              setTimeout(() => {
                this.props.clearcurrentFlashMessage();
              }, 2000);

              //  alert('The bulk upload request completed with errors, please see the downloaded file for more details')
            }
          });
      } else {
        this.props.sendFlashMessage(
          "Select Subgroup Using Checkbox",
          "alert-danger"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);

        //alert("First Select Contact Using Checkbox")
      }
    } else {
      this.props.sendFlashMessage(
        "first select the csv file of new learners!!",
        "alert-danger"
      );
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);

      //alert("first select the csv file of new learners!!");
    }
  };

  render() {
    const {
      onlygroups,
      csvwithgrouploading,
      csvuploadsuccesstrackmsg
    } = this.state;

    // const { user, changedpassloading } = this.props.auth;
    /*
    let csvuploaderrorsData
    if(csvuploaderrors !==null){
       csvuploaderrorsData = csvuploaderrors.map((data,index)=>{
        return <div className="row" style={{backgroundColor:"yellow",margin:5}}>
        <div className="col-md-12" style={{padding:10,backgroundColor:"#24434F",color:"#fff"}}>
                        
        Row Id :<span>{data.rowId}</span> ~ 
        common message :<span style={{color:"red"}}> {data.message}</span>
   
        </div>
                  {data.errorMessages.map((errData,index)=>{
                          return   <div className="col-md-4" style={{backgroundColor:"#e7e720",color:"black",border:"5px solid yellow"}}>
                                         Field : {errData.type} <br />
                                         Error : <span style={{color:"red"}}>{errData.message}</span>
                           </div>
                        })}
                        <br />
                      
           <br />
        </div>
      })
   
    }
  */

    let subgroupData = this.state.subgroupsall.map((data, index) => {
      return (
        <div className="form-group col-md-4" key={index}>
          <input
            type="checkbox"
            name="group2"
            value={this.state.assigngrpsmultiselectarr[index]}
            onClick={() => this._handlegroupingevent(data.id, data.name)}
          />{" "}
          {data.name}
        </div>
      );
    });

    let finalsubgroupCont = subgroupData;

    let actualonlygroups;
    let allgroupsarrobjsloading;
    if (onlygroups === null) {
      allgroupsarrobjsloading = "";
    } else {
      if (onlygroups && Object.keys(onlygroups).length > 0) {
        //TotalonlygroupsItem = Object.keys(onlygroups).length;
        // Select options for onlygroups
        actualonlygroups = onlygroups.map(w => ({
          label: w.name,
          value: w.id
        }));

        // console.log(actualwarehouses);
        /*  const firstoptinsellist = [
             { label: "* Select Your Warehouse", value: 0 }
           ];
           */

        // concat function is used for join two arrays
        //  warehouseoptions = firstoptinsellist.concat(actualonlygroups);

        // console.log(warehouseoptions);
      }
    }

    return (
      <div>
        <Sidebar />
        <div id="wrapper">
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Bulk Upload Learners
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <section className="content">
              <Flashmessages />

              <div className="container-fluid">
                <div className="col-md-12">
                  <div className="card">
                    {csvuploadsuccesstrackmsg && (
                      <div
                        className="alert alert-info alert-dismissible fade show"
                        role="alert"
                      >
                        {this.state.uploadPercentage > 0 && (
                          <Progress percentage={this.state.uploadPercentage} />
                        )}
                        <strong>Info : </strong> {csvuploadsuccesstrackmsg}
                      </div>
                    )}
                    {csvwithgrouploading ? (
                      <Spinner />
                    ) : (
                      <div>
                        <div className="form-group col-md-12">
                          <div className="panel-body col-12">
                            <div
                              className="col-md-4"
                              style={{ paddingBottom: 25, paddingTop: 25 }}
                            >
                              <label>Select Group</label>
                              {allgroupsarrobjsloading ? (
                                <span>Groups Loading...</span>
                              ) : onlygroups &&
                                Object.keys(onlygroups).length > 0 ? (
                                <Select
                                  value={this.state.groups}
                                  onChange={this.handlegroupsChange}
                                  options={actualonlygroups}
                                />
                              ) : (
                                <div>No Data</div>
                              )}
                            </div>

                            <div className="col-md-12">
                              <div className="form-group row col-12">
                                {finalsubgroupCont.length ? (
                                  <label>Select Sub Groups</label>
                                ) : (
                                  <span></span>
                                )}
                              </div>

                              <div className="form-group row">
                                {finalsubgroupCont}
                              </div>
                            </div>
                          </div>
                        </div>

                        <center>
                          {finalsubgroupCont.length ? (
                            <div
                              style={{
                                position: "fixed",
                                bottom: 0,
                                width: "77%",
                                padding: 20
                              }}
                            >
                              <form
                                onSubmit={this.onSubmitbycsv}
                                encType="multipart/form-data"
                              >
                                <div
                                  className="form-row"
                                  style={{ padding: 10 }}
                                >
                                  <div className="col">
                                    <input
                                      type="file"
                                      className="form-control-file"
                                      id="exampleFormControlFile1"
                                      name="newlearnerfile"
                                      onChange={this.onChangecsvfile}
                                    />
                                  </div>
                                  <div className="col">
                                    <input
                                      // onClick={this.onSubmit}
                                      type="submit"
                                      value="Upload"
                                      className="btn btn-success btn-block col-5"
                                    />
                                  </div>
                                </div>
                              </form>

                              <div className="alert alert-warning" role="alert">
                                <div className="row vertical-align">
                                  <div className="col-md-1 text-center">
                                    <i className="fa fa-exclamation-triangle fa-2x"></i>
                                  </div>
                                  <div className="col-md-11">
                                    <strong>Warning:</strong> Please try to
                                    upload a csv file with records below 10000
                                    otherwise the upload might time out. If you
                                    need to upload more than 10000 records,
                                    break them into two files or more of 10000
                                    records each
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </center>
                      </div>
                    )}

                    {/*csvuploaderrors !=null &&   <div className="container-fluid" style={{maxHeight:300,overflowY:"auto",margin:10}}>
          
                           <p>Csv Upload Errors Display</p>
                          
                           {csvuploaderrorsData && csvuploaderrorsData}
                          </div>*/}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

BulkuploadLearners.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage
})(withRouter(BulkuploadLearners));
