import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { clearCurrentProfile } from "../../actions/profileActions";

import "./css/myguestnav.css";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  onLogoutClick = () => {
    //const { isAuthenticated, user } = this.props.auth;

    this.props.logoutUser(); //here we call the logoutUser action

    //let route= `login/${councilId}`
    //this.props.history.push(route)
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;
    //const cartstockdtall = this.state.cartstockdtall;

    //console.log(testpurpose);

    const authLinks = (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* mobile sidebar start */}
        <ul className="nav-mobile">
          <li className="menu-container">
            <input id="menu-toggle" type="checkbox" />
            <label htmlFor="menu-toggle" className="menu-button">
              <svg className="icon-open" viewBox="0 0 24 24">
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
              </svg>
              <svg className="icon-close" viewBox="0 0 100 100">
                <path d="M83.288 88.13c-2.114 2.112-5.575 2.112-7.69 0L53.66 66.188c-2.113-2.112-5.572-2.112-7.686 0l-21.72 21.72c-2.114 2.113-5.572 2.113-7.687 0l-4.693-4.692c-2.114-2.114-2.114-5.573 0-7.688l21.72-21.72c2.112-2.115 2.112-5.574 0-7.687L11.87 24.4c-2.114-2.113-2.114-5.57 0-7.686l4.842-4.842c2.113-2.114 5.57-2.114 7.686 0l21.72 21.72c2.114 2.113 5.572 2.113 7.688 0l21.72-21.72c2.115-2.114 5.574-2.114 7.688 0l4.695 4.695c2.112 2.113 2.112 5.57-.002 7.686l-21.72 21.72c-2.112 2.114-2.112 5.573 0 7.686L88.13 75.6c2.112 2.11 2.112 5.572 0 7.687l-4.842 4.84z" />
              </svg>
            </label>
            <ul className="menu-sidebar">
              <li>
                <Link to="/dashboard" className="mlink">
                  Dashboard
                </Link>
              </li>
              <li>
                <Link to="/personal-account-setting" className="mlink">
                  Application Learners
                </Link>
              </li>
              <li>
                <Link to="/bulk-upload-learner" className="mlink">
                  Upload Learners
                </Link>
              </li>
              <li>
                <Link to="/view-jobs-id" className="mlink">
                  View Jobs Id
                </Link>
              </li>

              {/*}   <li>
                     <input type="checkbox" id="sub-two" class="submenu-toggle" />        
                     <label class="submenu-label" for="sub-two">Category</label>
                     <div class="arrow right">&#8250;</div> 
                     <ul class="menu-sub">
                       <li class="menu-sub-title">
                         <label class="submenu-label" for="sub-two">Back</label>
                         <div class="arrow left">&#8249;</div>              
                       </li>      
                       <li><a href="#">Sub-item</a></li>
                       <li><a href="#">Sub-item</a></li>
                       <li><a href="#">Sub-item</a></li>     
                       <li><a href="#">Sub-item</a></li>                                
                     </ul>              
                   </li>   
             */}
            </ul>
          </li>
        </ul>
        {/* mobile sidebar end */}

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <button
              className="nav-link"
              data-toggle="dropdown"
              style={{ background: "#343A40" }}
            >
              <i className="fas fa-th-large" style={{ color: "#fff" }}></i>
            </button>

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <button className="dropdown-item">
                <div className="media">
                  {/*<img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
                   */}{" "}
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      <span style={{ color: "green" }}>
                        {user.name ? user.name : <span>Organisation</span>}
                      </span>
                      {/*}  <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                       */}{" "}
                    </h3>
                    {/*}  <p className="text-sm">Call me whenever you can...</p>
                  <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
    */}{" "}
                  </div>
                </div>
              </button>

              <div className="dropdown-divider"></div>
              <span
                onClick={this.onLogoutClick}
                className="dropdown-item dropdown-footer"
                style={{ cursor: "pointer" }}
              >
                Logout
              </span>
            </div>
          </li>
        </ul>
      </nav>
    );

    const guestLinks = (
      <div className="myguestnav">
        <input type="checkbox" id="nav-check" />
        <div className="nav-header">
          <div className="nav-title">
            <Link
              to="/"
              style={{
                color: "#fff",
                textDecoration: "none",
                fontSize: 17,
                top: 1,
                position: "fixed"
              }}
            >
              {" "}
              ENGAGEMENT PLATFORM{" "}
            </Link>
          </div>
        </div>
        <div className="nav-btn">
          <label htmlFor="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        {/*<div className="nav-links">
        <a href="#" >Contact</a>
        <a href="#" >Help</a>
    </div>*/}
      </div>
    );

    return isAuthenticated ? authLinks : guestLinks;
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  logoutUser,
  clearCurrentProfile
})(withRouter(Navbar));
