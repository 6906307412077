import React, { Component } from "react";
//import Modal from "react-responsive-modal";
import "react-alice-carousel/lib/alice-carousel.css";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import axios from "axios";
import "./css/dashboard.css";

import "./css/model.css";
import Sidebar from "../layout/sidebar";

import Flashmessages from "../flashMessagesComp/flashMessages";

// import Comingsoonimg from "./images/coming-soon.png"
/*
const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="dashboard-modal-main">
        {children}
        <center>
          <button
            onClick={handleClose}
            className="btn btn-default"
            style={{
              textDecoration: "none",
              color: "white",
              backgroundColor: "#AF0808"
            }}
          >
            Close
          </button>
        </center>
      </section>
    </div>
  );
};
*/

class Dashboard extends Component {
  items = [1, 2, 3, 4, 5];

  constructor() {
    super();
    this.state = {
      show: false,
      querystr: "",
      countRecordsActiveUsers: "",
      countRecordsRegistered: "",
      countRecordsLoaded: "",
      countAppsDownload: "",
      countRecordsRejected: "",
      loading: false
    };

    this.onChange = this.onChange.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    //here we call the getCurrentProfile when componentDidMount

    this.setState({ loading: true });

    axios
      .get(`/api/v1/reports`)
      .then(res => {
        // console.log("dashboard report data is :" + res.data);

        this.setState({
          countRecordsActiveUsers: res.data.countRecordsActiveUsers,
          countRecordsRegistered: res.data.countRecordsRegistered,
          countRecordsLoaded: res.data.countRecordsLoaded,
          countAppsDownload: res.data.countAppsDownload,
          countRecordsRejected: res.data.countRecordsRejected,
          loading: false
        });
      })
      .catch(err => {
        console.log("report error is ", err);
        this.setState({ loading: false });
      });
  }

  onChange = e => {
    switch (e.target.name) {
      case "productImage":
        this.setState({ productImage: e.target.files[0] });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };
  onSubmit = () => {
    const { querystr } = this.state;

    console.log(querystr);
    if (querystr) {
      console.log("query string is : " + querystr);
      //  this.props.getAdvSearchInvStock(querystr);
    } else {
      console.log("search something..");
    }
  };

  windowPrint(e) {
    e.preventDefault();
    window.print();
  }

  handleKeyPress = event => {
    const { querystr } = this.state;

    if (event.key === "Enter" && querystr) {
      this.setState({
        show: true,
        querystr: querystr
      });
      console.log("enter press here! ");
      console.log("query string is : " + querystr);
      this.props.getAdvSearchInvStock(querystr);
    }
  };

  showModalClick = querystr => {
    if (querystr) {
      this.setState({
        show: true,
        querystr: querystr
      });
      this.props.getAdvSearchInvStock(querystr);
    } else {
      console.log("search something..");
    }
  };
  hideModalClick = () => {
    this.setState({ show: false, querystr: "" });
    console.log("querystr set to : '' ");
  };

  galleryItems() {
    return this.items.map(i => <h2 key={i}> {i}</h2>);
  }

  render() {
    /*  const {
      open,
      querystr,
      
    } = this.state;
    const { user } = this.props.auth;*/

    const {
      countRecordsActiveUsers,
      countRecordsRegistered,
      countRecordsLoaded,
      countAppsDownload,
      countRecordsRejected,
      loading
    } = this.state;

    return (
      <div>
        <Sidebar />
        <div className="content-wrapper">
          {/*<div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0 text-dark">Dashboard</h1>
                </div> 
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div> 
              </div> 
            </div> 
     </div> */}

          <section className="content">
            <Flashmessages />

            <div className="container-fluid" style={{ padding: 20 }}>
              <div className="row">
                {/*}  <div className="col-md-12" style={{ textAlign: "center" }}>
                  <h1 style={{ fontSize: 20, padding: 50 }}>Coming Soon</h1>
                   <img src={Comingsoonimg} alt="Coming Soon" className="img-responsive" style={{padding:20,maxWidth:350}}/>
                   
                </div>
                  */}

                <div className="col-md-4">
                  <div className="small-box bg-info">
                    <div className="inner">
                      {loading ? (
                        <h4>Loading...</h4>
                      ) : (
                        <h3>{countRecordsLoaded}</h3>
                      )}

                      <p>Records Loaded</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-bag"></i>
                    </div>
                    {/*} <a href="#" className="small-box-footer">
                      More info <i className="fas fa-arrow-circle-right"></i>
                </a>*/}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="small-box bg-success">
                    <div className="inner">
                      {loading ? (
                        <h4>Loading...</h4>
                      ) : (
                        <h3>{countRecordsRegistered}</h3>
                      )}

                      <p>Records Registered</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-stats-bars"></i>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="small-box bg-warning">
                    <div className="inner">
                      {loading ? (
                        <h4>Loading...</h4>
                      ) : (
                        <h3>{countRecordsActiveUsers}</h3>
                      )}

                      <p>Number of Active Users</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="small-box bg-danger">
                    <div className="inner">
                      {loading ? (
                        <h4>Loading...</h4>
                      ) : (
                        <h3>{countRecordsRejected}</h3>
                      )}

                      <p>Records Rejected</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph"></i>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="small-box"
                    style={{ backgroundColor: "burlywood" }}
                  >
                    <div className="inner">
                      {loading ? (
                        <h4>Loading...</h4>
                      ) : (
                        <h3>{countAppsDownload}</h3>
                      )}

                      <p>Number of App Downloads</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(withRouter(Dashboard));
