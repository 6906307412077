//import firebase from 'firebase/app';

import firebase from 'firebase/app';
import 'firebase/auth';

const config = { /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
  apiKey: "AIzaSyCi1V6ngRx0qVinhfyIWHzXvLccpy59CAg",
  authDomain: "wcea-ios.firebaseapp.com",
  databaseURL: "https://wcea-ios.firebaseio.com",
  projectId: "wcea-ios",
  storageBucket: "wcea-ios.appspot.com",
  messagingSenderId: "280393317145",
  appId: "1:280393317145:web:9156dc0b271493cf83a388"
};
const fire = firebase.initializeApp(config);
export default fire;
