import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Select from "react-select";
//import _ from "lodash";
//import { makeData, Logo, Tips } from "./Utils";
import Sidebar from "../layout/sidebar";

//import AddLearnerComponent from "../add-learner-user/AddLearnerUser"

//import EditLearnerComponent from "../edit-learner-user/EditLearnerUser"

import {
  sendFlashMessage,
  clearcurrentFlashMessage
} from "../../actions/flashMessage";
import Flashmessages from "../flashMessagesComp/flashMessages";

import Pagination from "../common/Pagination";

import "./paccsetting.css";

/*
const AddLearnerModal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="dashboard-modal-main">
          
          
          <div className="col-12" style={{textAlign:"end",backgroundColor:"#F5F5F5"}}>

          <i class="fas fa-times-circle"  onClick={handleClose} style={{fontSize:30,color:"red",cursor:"pointer",padding:20}}></i>
         
          </div>
          
         
        {children}
       
      </section>
    </div>
  );
};

const EditLearnerModal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="dashboard-modal-main">
      <div className="col-12" style={{textAlign:"end",backgroundColor:"#F5F5F5"}}>

      <i class="fas fa-times-circle"  onClick={handleClose} style={{fontSize:30,color:"red",cursor:"pointer",padding:20}}></i>
     
      </div>
        {children}
        
      </section>
    </div>
  );
};


const BulkUploadLearnerModal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="dashboard-modal-main">
      <div className="col-12" style={{textAlign:"end",backgroundColor:"#F5F5F5"}}>

      <i class="fas fa-times-circle"  onClick={handleClose} style={{fontSize:30,color:"red",cursor:"pointer",padding:20}}></i>
     
      </div>
        {children}
        
      </section>
    </div>
  );
};


const AssignGroupLearnerModal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="dashboard-modal-main">
      <div className="col-12" style={{textAlign:"end",backgroundColor:"#F5F5F5"}}>

      <i class="fas fa-times-circle"  onClick={handleClose} style={{fontSize:30,color:"red",cursor:"pointer",padding:20}}></i>
     
      </div>
        {children}
        
      </section>
    </div>
  );
};
*/

//const rawData = makeData();

class PersonalAccountSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      loggedinuser: {},
      currentpassword: "",
      newpassword: "",
      newpassword2: "",
      lcontactsdtall: [],
      show: false,
      showeditlearnermodal: false,
      showbulkupldlearnermodal: false,
      showassignlearnermodal: false,
      enabledisablearr: [],
      enabledisablemultiselectarr: [],
      multiselectarr: [],
      assigngrpsmultiselectarr: [],
      mobilemultiselectarr: [],
      lmsmultiselectarr: [],
      finalmultiselected: [],
      groupsall: [
        {
          id: "1",
          name: "g1"
        },
        {
          id: "2",
          name: "g2"
        },
        {
          id: "3",
          name: "g3"
        },
        {
          id: "4",
          name: "g4"
        }
      ],
      editlearnerid: "",
      loading: false,
      enabledisable: "",
      newlearnerfile: "",
      onlygroups: null,
      groupoptions: null,
      groups: null,
      subgroupsall: [],
      finalassigngrpmultiselectarr: [],
      allgroupsarrobjs: null,
      pages: null,
      setlearnerloading: true,
      sorted: []
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.onChangepassword = this.onChangepassword.bind(this);

    this.setLatestLearners = this.setLatestLearners.bind(this);

    this.reactContactTable = React.createRef();
  }

  componentDidMount() {
    //if isAuthenticated is not TRUE so redirect the user to /

    //this.props.getCurrentAllStock();

    // Github fetch library : https://github.com/github/fetch
    // Call the API page
    /* fetch('https://www.reddit.com/r/reactjs.json')
   .then((result) => {
      // Get the result
      // If we want text, call result.text()
      return result.json();
    }).then((jsonResult) => {
      // Do something with the result
      console.log(jsonResult);
    })*/

    this._hitwceagroupsapi();

    const currentreactcontactTable = this.reactContactTable.current;
    if (currentreactcontactTable) {
      const page = currentreactcontactTable.state.page;
      const pageSize = currentreactcontactTable.state.pageSize;
      console.log("from compdidmount page is : " + page);
      console.log("from compdidmount pageSize is : " + pageSize);
      const currentrctstatusobj = {
        page: page,
        pageSize: pageSize
      };
      this.setLatestLearners(currentrctstatusobj);
    }

    /* 
        axios.get(`/api/users/current/`).then(res => {
      this.setState({ loggedinuser: res.data });
      console.log("logged in user info : " + this.state.loggedinuser);
    });
    */
  }

  _hitwceagroupsapi = () => {
    axios
      .get("/api/v1/wcea/groups")
      .then(res => {
        this.setState({ allgroupsarrobjs: res.data });

        var onlygroupsData = this.state.allgroupsarrobjs.filter(function(grp) {
          return grp.parentId === null;
        });

        console.log(onlygroupsData);
        this.setState({ onlygroups: onlygroupsData });

        /*   var onlysubgroupsData =  res.data.filter(function(grp) {
          return grp.parentId !== null;
        });
         
        console.log(onlysubgroupsData)

 this.setState({subgroupsall:onlysubgroupsData})*/
      })
      .catch(err => {
        console.log("error is : " + err);
        // alert("Something went wrong!!")
      });
  };

  onEditLearnerClick(id) {
    console.log(id);
    this.showEditModalClick(id);
    //   alert("EDIT LEARNER : hit api for learner user by its id")
    //  this.props.singleprodstockbyid(id, this.props.history); //this.props.history => this allows to do redirect functionality in deleteStock -> this action for this we use withRouter when we export class component

    // this.props.history.push("/edit-stock");
  }

  onBulkuploadLearnerClick() {
    this.showBulkUploadModalClick();
  }

  onAssigngroupLearnerClick() {
    this.showAssigngroupModalClick();
  }

  onDeleteLearnerUserClick(id) {
    console.log("learner contact id is : " + id);

    axios
      .delete(`/api/v1/contacts/${id}`)
      .then(res => {
        console.log("Delete Successfulyy");
        //   this.setLatestLearners()
        alert("Learner Delete Successfulyy!!");
      })
      .catch(err => {
        console.log("error is : " + err);
        //  alert("Something went wrong!!")
      });

    // alert("DELETE LEARNER :hit api for learner user by its id")

    // this.props.deleteInventoryUserbyid(id, this.props.history); //this.props.history => this allows to do redirect functionality in deletecompany -> this action for this we use withRouter when we export class component
  }

  setLatestLearners(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `setlearnerloading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ setlearnerloading: true });

    console.log("pageSize value is : " + state.pageSize); //10 data per page as we defined
    console.log("page value is : " + state.page); //current request page //at starting its 0 value for page
    // console.log("sorted value is : "+state.sorted)
    //console.log("filtered value is : "+state.filtered)

    const requestData = (pageSize, page, sorted, filtered) => {
      // console.log("Fetch data: sorted -> ", JSON.stringify(sorted));
      return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        //  let filteredData = rawData; //array of objects (random generated local fake data)

        axios
          .get(
            `/api/v1/contacts?page=${page}&size=${pageSize}&registeredType="Yes"`
          )
          .then(res => {
            var filteredData = res.data.content;
            //this.setState({ lcontactsdtall: res.data.content });
            //console.log("filteredData is below: ")
            //  console.log(filteredData)

            // You must return an object containing the rows of the current page, and optionally the total pages number.
            const fres = {
              //  rows: sortedData.slice(pageSize * page, pageSize * page + pageSize),
              // pages: Math.ceil(filteredData.length / pageSize)
              rows: filteredData,
              pages: res.data.totalPages
            };

            // Here we'll simulate a server response with 500ms of delay.
            setTimeout(() => resolve(fres), 500);

            //this.setState({loading:false})
          })
          .catch(err => {
            this.setState({ setlearnerloading: false });
            console.log("application request data is not getting !!");
            console.log("application request error is : " + err);
            // alert("Something went wrong!!")
          });
      });
    };

    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(state.pageSize, state.page, state.sorted, state.filtered).then(
      res => {
        // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
        if (res) {
          this.setState({
            lcontactsdtall: res.rows,
            pages: res.pages,
            setlearnerloading: false
          });
        } else {
          console.log("application request data is not getting !!");
        }
      }
    );
  }

  onChangepassword(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChange = e => {
    const state = this.state.loggedinuser;

    state[e.target.name] = e.target.value;

    this.setState({ loggedinuser: state });
  };

  onSubmit = () => {
    const { email, mobile, name, website } = this.state.loggedinuser; //take prev value that was set in repsonse

    const userData = {
      name,
      email,
      mobile,
      website
    };

    console.log(
      "final pacc data : " + userData.name,
      userData.website,
      userData.mobile,
      userData.email
    );
    this.props.updateUserInfo(userData, this.props.history);
  };

  handleSubmit = e => {
    if (e) e.preventDefault();
    const { currentpassword, newpassword, newpassword2 } = this.state;

    const userchangepassData = {
      currentpassword,
      newpassword,
      newpassword2
    };

    console.log(
      "final userchangepassData : " + userchangepassData.currentpassword,
      userchangepassData.newpassword,
      userchangepassData.newpassword2
    );
    this.props.changedUserPassword(userchangepassData, this.props.history);
  };

  showModalClick = e => {
    this.setState({
      show: true
    });
    console.log("show set to true ");
  };

  showBulkUploadModalClick = () => {
    this.setState({
      showbulkupldlearnermodal: true
    });
  };

  showAssigngroupModalClick = () => {
    this.setState({
      showassignlearnermodal: true
    });
  };

  showEditModalClick = id => {
    this.setState({
      showeditlearnermodal: true
    });

    this.setState({
      editlearnerid: id
    });

    console.log("showeditlearnermodal set to true ");
    console.log("editlearnerid is : " + id);
  };

  hideModalClick = () => {
    this.setState({ show: false });
    console.log("show set to false ");
  };
  hideEditModalClick = () => {
    this.setState({ showeditlearnermodal: false, editlearnerid: "" });
    console.log("showeditlearnermodal set to false ");
  };

  hideBulkUploadModalClick = () => {
    this.setState({ showbulkupldlearnermodal: false });
  };

  hideAssigngroupModalClick = () => {
    this.setState({ showassignlearnermodal: false });
  };

  onEnaDisabLearnerUserClick = (published, index) => {
    console.log(
      "published enable/disable value is : " +
        published +
        " and index is :" +
        index
    );
    // alert("here we wil hit API for published enable/disable where is : "+id)
  };

  _handlemultiselectgroup = id => {
    const { finalmultiselected } = this.state;

    //here we make multiselected array of user id

    //first we check inside array if user id is found then we remove from the array
    //otherwise we will push the user id in array
    console.log("selected user id is : " + id);

    if (finalmultiselected.length === 0) {
      console.log("finalmultiselected array is null so we init by id");
      finalmultiselected.push(id);
    } else {
      var idexist = finalmultiselected.includes(id);

      if (idexist) {
        console.log("exist id in array : " + idexist);
        var index = finalmultiselected.indexOf(id);

        console.log("removing id index found : " + index);

        finalmultiselected.splice(index, 1);
      } else {
        console.log("not exist id in array : " + idexist);
        finalmultiselected.push(id);
      }
    }
  };

  _handleenabledisablemulti = (index, value) => {
    console.log("_handleenabledisablemulti execution start !!");

    console.log("index is : " + index + " and value is : " + value);
    /*
    const {finalmultiselected}=this.state

    console.log(finalmultiselected)

    var token = localStorage.getItem("jwtToken");
    //console.log("token is : "+token)
    var config = {
      headers: {'Authorization': `Bearer ${token}`}
  }; 
       axios.put("/api/contacts/published/",finalmultiselected,config).then(res => {
  alert("Operation performed succesfully !!")
        this.setLatestLearners()
       
      }).catch(err=>{
        console.log("error is : "+err)
        alert("Something went wrong!!")
      })

      */
  };

  onChangehandlemobile = (e, index) => {
    console.log("mobile target index is : " + index);
  };

  onClickhandleenabdisab = (e, index, enablestatus) => {
    const { finalassigngrpmultiselectarr } = this.state;

    console.log("selected groups for changing status array is below : ");

    console.log(finalassigngrpmultiselectarr);

    console.log("enable status is : " + enablestatus); //could be accepted or rejected

    console.log("index is : " + index + " where value is : " + enablestatus);

    // console.log("contact object is below !! ")
    let ftargetedcontactchanged = Object.assign(
      {},
      this.state.lcontactsdtall[index]
    ); //copy target contact obj
    ftargetedcontactchanged.isRegistered = enablestatus;
    ftargetedcontactchanged.contactGroup = finalassigngrpmultiselectarr; //here we assign arr of obj of selected groups

    //let finalupdatedcontact=this.state.lcontactsdtall[index]

    let id = this.state.lcontactsdtall[index].id; //using this id we will set isRegistered at backend

    // console.log("and also call the setLatestLearners")

    if (finalassigngrpmultiselectarr.length > 0) {
      console.log("here we will hit api for enable/disable published !!");
      console.log("contact id is : " + id);
      console.log("finalupdatedcontact is :");
      console.log(ftargetedcontactchanged); //here we get final targeted object by index with changes

      axios
        .put(`/api/v1/contacts/` + id, ftargetedcontactchanged)
        .then(res => {
          this.props.sendFlashMessage(
            "Enabled successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);

          // alert("Enabled successfully !!")

          const currentreactcontactTable = this.reactContactTable.current;
          if (currentreactcontactTable) {
            const page = currentreactcontactTable.state.page;
            const pageSize = currentreactcontactTable.state.pageSize;
            console.log("from compdidmount page is : " + page);
            console.log("from compdidmount pageSize is : " + pageSize);
            const currentrctstatusobj = {
              page: page,
              pageSize: pageSize
            };
            this.setLatestLearners(currentrctstatusobj);
          }
        })
        .catch(err => {
          console.log("error is : " + err);
          this.props.sendFlashMessage(
            "Something went wrong !!",
            "alert-danger"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);

          // alert("Something went wrong!!")
        });
    } else {
      this.props.sendFlashMessage(
        "please select groups and subgroup first !!",
        "alert-danger"
      );
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);

      //alert("please select groups and subgroup first !!")
    }

    // this.setState({ [e.target.name]: e.target.value });
  };

  _handlecomingsoon = () => {
    alert("Feature is coming soon !!");
  };

  onChangecsvfile = e => {
    switch (e.target.name) {
      case "newlearnerfile":
        this.setState({ newlearnerfile: e.target.files[0] });
        break;
      default:
        this.setState({ newlearnerfile: "no data" });
    }
  };

  onSubmitbycsv = async e => {
    e.preventDefault();

    const { newlearnerfile } = this.state;

    if (newlearnerfile) {
      console.log("selected csv flle is below :");
      console.log(newlearnerfile);
      let newLearnerData = new FormData();
      newLearnerData.append("file", newlearnerfile);

      axios
        .post("/api/v1/uploads", newLearnerData)
        .then(res => {
          let filename = res.data;
          console.log("filename received which is : " + filename);

          let data = {
            filename: filename
          };

          axios.post(`/api/contacts/uploads`, data).then(res => {
            alert("learner added by csv successfully !!");

            this.setState({ newlearnerfile: "" });

            //this.setLatestLearners()

            this.setState({ newlearnerfile: "" });
            this.hideBulkUploadModalClick();
          });
        })
        .catch(err => {
          console.log("error is : " + err);
          alert("Something went wrong!!");
        });

      /*  const parseFile = newlearnerfile => {
        return new Promise(resolve => {
          Papa.parse(newlearnerfile, {
            header: true,
            skipEmptyLines: true,
            complete: results => {
              resolve(results.data);
            }
          });
        });
      };

      var jsonstockdata = await parseFile(newlearnerfile);

      var addnewlearnerbycsv = {
        newlearnerfile: jsonstockdata
      };

     
      if (addnewlearnerbycsv) {
      console.log("addnewlearnerbycsv is below !!"); 
       console.log(addnewlearnerbycsv);

      }

     */
    } else {
      alert("first select the csv file of new learners!!");
    }
  };

  handlefinalAssigngroup = () => {
    const { finalmultiselected, finalassigngrpmultiselectarr } = this.state;

    if (finalmultiselected.length) {
      console.log("selected id array is below :");

      console.log(finalmultiselected);

      //finalmultiselected=>array of selected id
      //finalassigngrpmultiselectarr=>array of object of selected assign groups
      console.log("here we will assign group api hit");

      let finaldata = [];

      for (var i = 0; i < finalmultiselected.length; i++) {
        let middata = {
          id: finalmultiselected[i],
          contactGroup: finalassigngrpmultiselectarr
        };
        finaldata.push(middata);
      }

      console.log(finaldata);

      axios
        .put("/api/contacts/groups", finaldata)
        .then(res => {
          this.setState({
            finalmultiselected: [],
            finalassigngrpmultiselectarr: [],
            groups: "",
            subgroupsall: []
          });
          this._hitwceagroupsapi();

          this.hideAssigngroupModalClick();
          alert("Group Assign Successfully !!");
        })
        .catch(err => {
          console.log("error is : " + err);
          // alert("Something went wrong!!")
        });
    } else {
      alert("First Select Contact Using Checkbox");
    }
  };

  _handlegroupingevent = (id, name) => {
    const { finalassigngrpmultiselectarr } = this.state;

    console.log("is is : " + id + " name is : " + name);

    //here we first check inside array of object if in any object
    //there id found so we will splice object otherwise we insert new object with id and name
    let idfound = true;
    for (var i = 0; i < finalassigngrpmultiselectarr.length; i++) {
      if (finalassigngrpmultiselectarr[i].id === id) {
        console.log("id found inside array of object");

        finalassigngrpmultiselectarr.splice(i, 1);

        idfound = false;
      }
    }

    if (idfound) {
      console.log("new entry");

      let newentry = {
        id: id,
        name: name
      };
      finalassigngrpmultiselectarr.unshift(newentry);
    }
  };

  handlegroupsChange = groups => {
    this.setState({ groups });
    console.log(`Option selected:`, groups);

    let id = groups.value;
    var onlysubgroupsData = this.state.allgroupsarrobjs.filter(function(grp) {
      return grp.parentId === id;
    });

    console.log(onlysubgroupsData);

    this.setState({ subgroupsall: onlysubgroupsData });

    // let groupid=groups.value
    //this.handlesetsubgroup(groupid)
  };

  checkhasdownloadedvalue = value => {
    if (value == null || value === "No" || value === "opted out") {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { lcontactsdtall, onlygroups, setlearnerloading, pages } = this.state;

    //const { user } = this.props.auth;

    let subgroupData = this.state.subgroupsall.map((data, index) => {
      return (
        <div className="form-group col-md-4" key={index}>
          <input
            type="checkbox"
            name="group2"
            value={this.state.assigngrpsmultiselectarr[index]}
            onClick={() => this._handlegroupingevent(data.id, data.name)}
          />{" "}
          {data.name}
        </div>
      );
    });

    let finalsubgroupCont = subgroupData;

    let actualonlygroups;
    let allgroupsarrobjsloading;
    if (onlygroups === null) {
      allgroupsarrobjsloading = "";
    } else {
      if (onlygroups && Object.keys(onlygroups).length > 0) {
        //TotalonlygroupsItem = Object.keys(onlygroups).length;
        // Select options for onlygroups
        actualonlygroups = onlygroups.map(w => ({
          label: w.name,
          value: w.id
        }));

        // console.log(actualwarehouses);
        /*  const firstoptinsellist = [
             { label: "* Select Your Warehouse", value: 0 }
           ];
           */

        // concat function is used for join two arrays
        //  warehouseoptions = firstoptinsellist.concat(actualonlygroups);

        // console.log(warehouseoptions);
      }
    }

    // Select options for enable/disable
    /*const enabledisableoptions = [ 
      { label: "Requested", value: "Requested" },
      { label: "Accepted", value: "Yes" },
      { label: "Rejected", value: "No" }
    ];*/

    let columns = [];

    columns = [
      {
        Header: "id",
        accessor: "id",
        minWidth: 100,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "First Name",
        accessor: "firstName",
        minWidth: 200,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        minWidth: 200,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "Organisation",
        accessor: "company",
        minWidth: 200,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "Mobile No",
        accessor: "mobile",
        minWidth: 200,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "Email",
        accessor: "email",
        minWidth: 200,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "Position",
        accessor: "position",
        minWidth: 200,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "Organisation Id",
        accessor: "wceaOrganisationUid",
        minWidth: 200,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "Licence No",
        accessor: "wceaLicenseNumber",
        minWidth: 200,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "Index Number",
        accessor: "wceaIndexNumber",
        minWidth: 200,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "CPD Date",
        accessor: "cpddate",
        maxWidth: 200,
        filterable: false,
        Cell: row => (
          <span>
            <center>{row.value}</center>
          </span>
        )
      },
      {
        Header: "Enabled",
        accessor: "isRegistered",
        maxWidth: 300,
        filterable: false,
        Cell: row => (
          <span>
            <center style={{ color: "goldenrod" }}>{row.value}</center>
          </span>
        )
      },
      {
        Header: "Accepted",
        accessor: "isRegistered",
        maxWidth: 300,
        filterable: false,
        Cell: row => (
          <span>
            <center>
              <button
                onClick={e => this.onClickhandleenabdisab(e, row.index, "Yes")}
                className="btn btn-sm btn-success"
              >
                <i className="fa fa-check-square"></i> Accepted
              </button>
            </center>
          </span>
        )
      },
      {
        Header: "Rejected",
        accessor: "isRegistered",
        maxWidth: 300,
        filterable: false,
        Cell: row => (
          <span>
            <center>
              <button
                onClick={e => this.onClickhandleenabdisab(e, row.index, "No")}
                className="btn btn-sm btn-danger"
              >
                <i className="fa fa-window-close" aria-hidden="true"></i>{" "}
                Rejected
              </button>
            </center>
          </span>
        )
      }
    ];

    return (
      <div>
        <Sidebar />
        <div id="wrapper">
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Application Requests
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <section className="content">
              <Flashmessages />

              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group col-md-12">
                      <div className="panel-body col-12">
                        <div className="col-md-4" style={{ paddingBottom: 25 }}>
                          <label>Select Group</label>

                          {allgroupsarrobjsloading ? (
                            <span>Groups Loading...</span>
                          ) : onlygroups &&
                            Object.keys(onlygroups).length > 0 ? (
                            <Select
                              value={this.state.groups}
                              onChange={this.handlegroupsChange}
                              options={actualonlygroups}
                            />
                          ) : (
                            <div>No Data</div>
                          )}
                        </div>

                        <div className="col-md-12">
                          <div className="form-group row col-md-12">
                            {finalsubgroupCont.length ? (
                              <label>Select Sub Groups</label>
                            ) : (
                              <span></span>
                            )}
                          </div>

                          <div className="form-group row">
                            {finalsubgroupCont}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      {/*lcontactsdtallCont&&lcontactsdtallCont*/}
                      <ReactTable
                        PaginationComponent={Pagination}
                        ref={this.reactContactTable}
                        data={lcontactsdtall}
                        columns={columns}
                        defaultPageSize={10}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        pages={pages} // Display the total number of pages
                        loading={setlearnerloading} // Display the loading overlay when we need it
                        onFetchData={this.setLatestLearners} // Request new data when things change
                        sorted={this.state.sorted}
                        onSortedChange={(newSort, column) => {
                          this.setState({ sorted: newSort });
                        }}
                        //  showPaginationTop={false}
                        //  showPaginationBottom={true}

                        /* pageSizeOptions={[
                        5,
                        10,
                        15,
                        20,
                        25,
                        30,
                        35,
                        40,
                        50
                      ]}*/
                        style={
                          {
                            //  height: "600px" // This will force the table body to overflow and scroll, since there is not enough room
                            /*, maxWidth: "800px" */
                          }
                        }
                        className="-striped -highlight"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

PersonalAccountSetting.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage
})(withRouter(PersonalAccountSetting));
