import React, { Component } from "react";

//import axios from "axios";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { clearCurrentProfile } from "../../actions/profileActions";
//import Spinnersload from "../dashboard/spinners/Spinner";
class sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartstockdtall: [],
      cartitemlength: "",
      companylogo: "",
      minqtyreqnotify: null,
      councillogo: "",
      loading: ""
    };
  }

  componentDidMount() {
    // const { user } = this.props.auth;
    // let councilId=user.id
    //  console.log("logged id council id is : "+councilId)

    let councillogoImg = localStorage.getItem("councillogo");
    if (councillogoImg) {
      console.log("council logo found !!");
      this.setState({ councillogo: councillogoImg });
    }
  }
  componentWillReceiveProps(nextProps) {}
  onLogoutClick(e) {
    e.preventDefault();
    //  this.props.clearCurrentProfile(); //here we call the clearCurrentProfile for clear current user profile state and set to null in profileReducer.js case CLEAR_CURRENT_PROFILE execute
    this.props.logoutUser(); //here we call the logoutUser action
  }

  render() {
    const { councillogo } = this.state;
    //  const { isAuthenticated, user } = this.props.auth;
    //const cartstockdtall = this.state.cartstockdtall;

    return (
      <div>
        <aside
          style={{ position: "fixed" }}
          className="main-sidebar sidebar-dark-primary elevation-4"
        >
          <Link to="/dashboard" className="brand-link">
            {/*<img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3"
               style={{opacity:"0.8"}} />*/}

            {councillogo && (
              <img
                src={councillogo}
                alt="Logo"
                className="brand-image img-circle elevation-3"
                style={{ opacity: "0.8" }}
              />
            )}

            <span className="brand-text font-weight-light">Organisation</span>
          </Link>

          <div className="sidebar">
            {/*  <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
            
             <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
             
            </div>
          <div className="info">
              <a href="JavaScript:Void(0);" className="d-block">Hi, {user.name?user.name:<span>Organisation</span>}</a>
</div>
          </div>
     */}
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                <li className="nav-item">
                  <Link to="/dashboard" className="nav-link">
                    <i className="nav-icon fas fa-th"></i>
                    <p>
                      Dashboard
                      {/*} <span className="right badge badge-danger" style={{fontSize:8,marginTop:-12}}>New</span>
                       */}{" "}
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/personal-account-setting" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>
                      Application Requests
                      {/*} <span className="right badge badge-danger" style={{fontSize:8,marginTop:-12}}>New</span>
                       */}{" "}
                    </p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/bulk-upload-learner" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>
                      Upload Learners
                      {/*} <span className="right badge badge-danger" style={{fontSize:8,marginTop:-12}}>New</span>
                       */}{" "}
                    </p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/view-jobs-id" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>
                      View Jobs Id
                      {/*} <span className="right badge badge-danger" style={{fontSize:8,marginTop:-12}}>New</span>
                       */}{" "}
                    </p>
                  </Link>
                </li>
                {/*}
              <li className="nav-item">
                <Link to="/change-password" className="nav-link">
                  <i className="nav-icon fas fa-podcast"></i>
                  <p>
                  Change Password
                  </p>
                </Link>
              </li>*/}
              </ul>
            </nav>

            <div
              className="mt-3 pb-3 mb-3 ml-3 d-flex"
              style={{ position: "fixed", bottom: "0px" }}
            >
              <div className="image">
                {councillogo && (
                  <img
                    src={councillogo}
                    alt="Logo"
                    className="img-circle elevation-3"
                    style={{ opacity: "0.8", maxWidth: 125, marginLeft: 20 }}
                  />
                )}
              </div>
            </div>
          </div>
        </aside>
      </div>
    );
  }
}

sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  logoutUser,
  clearCurrentProfile
})(sidebar);
