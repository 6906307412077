import React, { Component } from "react";
import axios from "axios";
import { Router, Route, Switch } from "react-router-dom";

import history from "./history";

import jwt_decode from "jwt-decode";
//import setAuthToken from "./utils/setAuthToken";
import LocalStorageService from "./utils/LocalStorageService";

import { setCurrentUser, logoutUser } from "./actions/authActions";

import { Provider } from "react-redux";
import store from "./store";

import PrivateRoute from "./components/common/PrivateRoute";

import Navbar from "./components/layout/Navbar";
import Component404 from "./components/errors/Component404";

import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";

import PersonalAccountSetting from "./components/personal-account-setting/PersonalAccountSetting";

//import AddLearnerUser from "./components/add-learner-user/AddLearnerUser";

import BulkuploadLearners from "./components/bulk-upload-learners/BulkuploadLearners";
import ViewJobsId from "./components/view-jobs-id/ViewJobsId";
import ShowJobId from "./components/show-job-id/ShowJobId";

import "./App.css";

// LocalstorageService
const localStorageService = LocalStorageService.getService();

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers["X-Authorization"] = token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

//Add a response interceptor
axios.interceptors.response.use(
  response => {
    return response;
  },
  function(error) {
    // const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      !window.location.pathname.endsWith("/login")
    ) {
      //getting 401 and if route is not /login
      // router.push('/login');
      //to route to login in case of 401 except when on login page
      console.log(
        "unauthenticated at pathname " +
          window.location.pathname +
          " routing to login"
      );
      store.dispatch(logoutUser()); //remove token with set isAuthenticate=false in redux store
      // window.location.href = "/login";
      return Promise.reject(error);
    }

    // if (error.response.status === 401 && !originalRequest._retry) {

    //     originalRequest._retry = true;
    //     const refreshToken = localStorageService.getRefreshToken();
    //     return axios.post('/auth/token',
    //         {
    //             "refresh_token": refreshToken
    //         })
    //         .then(res => {
    //             if (res.status === 201) {
    //                 localStorageService.setToken(res.data);
    //                 axios.defaults.headers.common['X-Authorization'] =  localStorageService.getAccessToken();
    //                 return axios(originalRequest);
    //             }
    //         })
    // }
    return Promise.reject(error);
  }
);

// Check for token for every single page request
//find jwttoken in chrome developer tool in Local Storage where key is jwtToken and value Bearer-----so on
if (localStorage.jwtToken) {
  // Set auth token header auth

  //var token = localStorage.getItem("jwtToken");

  //var councilId = localStorage.getItem("councilId");

  // console.log("asyncCouncilGetMeFunc execution is start ");
  //   (async function asyncCouncilGetMeFunc() {
  //     try {
  //       // fetch data from a url endpoint
  //       const res = await axios.get("/api/v1/councils/me");
  //       let loggedinuserdata= res.data;
  //       // Set user and isAuthenticated to true with user data
  //        store.dispatch(setCurrentUser(loggedinuserdata));
  //        console.log("user dispatch !!")
  //     } catch(error) {
  //       // appropriately handle the error
  //       console.log("council me error is : "+error)

  //     }
  //   })()

  //   console.log("asyncCouncilGetMeFunc execution is end ");

  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user (eg : name,email,token details like exp etc ) and isAuthenticated=true
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.log("Token Expired");
    //decoded.exp => in object its has exp value if this exp value is less then the currentTime so we logout the user and redirect user to the /login
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    //  window.location.href = `/login?councilId=${councilId}`;
  }
}

var NotFound = ({ location }) => (
  <Component404 pathnotfound={location.pathname} />
);

class App extends Component {
  constructor(props) {
    super();

    this.state = {
      isFull: false
    };
  }

  goFull = () => {
    this.setState({ isFull: true });
  };

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="App">
            <Navbar />
            <Switch>
              {/* /login?councilId=3 */}
              <Route exact path="/" component={Login} />{" "}
              {/* by default at root login component load with default background */}
              <Route exact path={`/login`} component={Login} />{" "}
              {/* This route will help us for hit /login?councilId=3 route* then council bg show as per councilId/}
            
            {/*}  <Route exact path="/login/:councilId" component={Login} /> */}
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute
                exact
                path="/personal-account-setting"
                component={PersonalAccountSetting}
              />
              <PrivateRoute
                exact
                path="/bulk-upload-learner"
                component={BulkuploadLearners}
              />
              <PrivateRoute exact path="/view-jobs-id" component={ViewJobsId} />
              <PrivateRoute
                exact
                path="/show-job-id/:jobid"
                component={ShowJobId}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
