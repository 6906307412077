import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Spinner from "../common/Spinner";

import Sidebar from "../layout/sidebar";
import {
  sendFlashMessage,
  clearcurrentFlashMessage
} from "../../actions/flashMessage";
import Flashmessages from "../flashMessagesComp/flashMessages";

import Pagination from "../common/Pagination";

import "./jbccsetting.css";

class ViewJobsId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      jobid: "",
      loading: false,
      jobsloading: false,
      listofjobs: null
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState({ jobsloading: true });
    axios
      .get(`/api/v1/batch/listofjobs`)
      .then(res => {
        // console.log("listofjobs data is :" + res.data);
        this.setState({ listofjobs: res.data, jobsloading: false });
      })
      .catch(err => {
        console.log("report error is ", err);
        this.setState({ jobsloading: false });
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  _handlecomingsoon = () => {
    alert("Feature is coming soon !!");
  };

  handleBulkUploadCsvRejectedData = async (e, jobid) => {
    e.preventDefault();
    this.props.sendFlashMessage("Coming Soon !!", "alert-info");
    setTimeout(() => {
      this.props.clearcurrentFlashMessage();
    }, 2000);

    // try {
    //   this.setState({ loading: true });

    //   let axiosresdata = await axios.get(
    //     `/p/batch/errors/contactuploads?jobId=${jobid}`
    //   );

    //   if (axiosresdata.data) {
    //     const rejectedcsvData = axiosresdata.data;
    //     console.log("rejectedcsvData", rejectedcsvData);
    //     const fileData = rejectedcsvData;
    //     const blob = new Blob([fileData], { type: "text/csv" });
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.download = "bulkupload-rejected-record.csv";
    //     link.href = url;
    //     link.click();

    //     this.props.sendFlashMessage(
    //       "Found Some Rejected Records !!",
    //       "alert-success"
    //     );
    //     setTimeout(() => {
    //       this.props.clearcurrentFlashMessage();
    //     }, 2000);
    //     this.setState({ jobid: "", loading: false });
    //   } else {
    //     this.setState({ loading: false });
    //     console.log("NO DATA FOUND");
    //     this.props.sendFlashMessage("NO DATA FOUND !!", "alert-info");
    //     setTimeout(() => {
    //       this.props.clearcurrentFlashMessage();
    //     }, 2000);
    //   }
    // } catch (err) {
    //   this.setState({ jobid: "", loading: false });
    //   console.log("jobid api error is ", err);
    //   this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
    //   setTimeout(() => {
    //     this.props.clearcurrentFlashMessage();
    //   }, 2000);
    // }
  };

  render() {
    const { loading, jobsloading, listofjobs } = this.state;

    let columns = [
      {
        Header: "Job id",
        accessor: "JobId",
        filterable: true,
        Cell: row => (
          <span>
            <center>
              <Link
                style={{
                  color: "green",
                  fontSize: 16,
                  cursor: "pointer",
                  fontWeight: 500
                }}
                //onClick={(e) => this.handleBulkUploadCsvRejectedData(e,row.value)}
                to={`/show-job-id/` + row.value}
              >
                {row.value}
              </Link>
            </center>
          </span>
        )
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: row => (
          <span>
            <center>
              <p style={{ color: "black", fontSize: 16, fontWeight: 500 }}>
                {row.value}
              </p>
            </center>
          </span>
        )
      }
    ];

    let listofjobsCont;
    if (listofjobs === null || jobsloading) {
      listofjobsCont = (
        <div>
          <p style={{ padding: 10, textAlign: "center" }}>
            Jobs are loading. please wait...
          </p>
          <Spinner />
        </div>
      );
    } else {
      if (listofjobs && listofjobs.length) {
        listofjobsCont = (
          <div>
            <ReactTable
              data={listofjobs}
              columns={columns}
              defaultPageSize={10}
              PaginationComponent={Pagination}
              style={
                {
                  //  height: "600px" // This will force the table body to overflow and scroll, since there is not enough room
                  /*, maxWidth: "800px" */
                }
              }
              className="-striped -highlight"
            />
          </div>
        );
      } else {
        listofjobsCont = (
          <div className="col-12" style={{ textAlign: "center" }}>
            <p>Sorry, No Job Id Found</p>
          </div>
        );
      }
    }

    return (
      <div>
        <Sidebar />
        <div id="wrapper">
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-left">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">View Jobs Id</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <section className="content">
              <Flashmessages />

              <div className="container-fluid">
                <div className="col-md-8">
                  <div className="card">
                    {loading ? (
                      <div>
                        <p style={{ padding: 10, textAlign: "center" }}>
                          Processing your request. please wait...
                        </p>
                        <Spinner />
                      </div>
                    ) : (
                      <div>{listofjobsCont && listofjobsCont}</div>
                    )}

                    {/*csvuploaderrors !=null &&   <div className="container-fluid" style={{maxHeight:300,overflowY:"auto",margin:10}}>
          
                           <p>Csv Upload Errors Display</p>
                          
                           {csvuploaderrorsData && csvuploaderrorsData}
                          </div>*/}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

ViewJobsId.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors //here we listen the errors from the server response in root reducer
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage
})(withRouter(ViewJobsId));
