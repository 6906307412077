import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

/*
var token = localStorage.getItem("jwtToken");
  
var councilId = localStorage.getItem("councilId");

console.log("from private route token is : "+token)
console.log("from private route token is : "+councilId)
*/

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated === true ? ( //here its check if user is login
        <Component {...props} />
      ) : (
        //else redirect to /login
        <Redirect to="/login" />
      )
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
