import axios from "axios";
 
const setAuthToken = (token) => {
 // var token = localStorage.getItem("jwtToken");
    //console.log("token is : "+token)
   /* var config = {
      headers: {'Authorization': `Bearer ${token}`}
  };*/
  if (token) {  
    //if token is exist after successfully login
    // then token is Apply to every request in Authorization header
    axios.defaults.headers.common["X-Authorization"] = token;
  } else {
    //if token is not exist after login
    // Delete auth header
    delete axios.defaults.headers.common["X-Authorization"];
  }
};

export default setAuthToken;
