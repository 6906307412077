import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
//import jwt_decode from "jwt-decode";

//import history from "../history"

import {
  GET_USER_LOGIN_LOADING_START,
  GET_USER_LOGIN_LOADING_STOP,
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_CURRENT_USER_RESET_PASS_DATA,
  GET_CHANGED_PASSWORD_LOADING_START,
  GET_CHANGED_PASSWORD_LOADING_STOP,
  GET_FORGOT_PASSWORD_LOADING_START,
  GET_FORGOT_PASSWORD_LOADING_STOP
} from "./types";

import { sendFlashMessage, clearcurrentFlashMessage } from "./flashMessage";
//import { addFlashMessage } from "./MyflashMessages";
import fire from "../config/Fire";

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post("/api/users/register", userData) //here we hit the backend API
    .then(res => history.push("/login")) //if its successfull then redirect to login
    .catch((
      err //here we catch the err object when user perform registration
    ) =>
      dispatch({
        //here we call the dispatch
        //here we dispatch the type with data to the reducer
        type: GET_ERRORS,
        payload: err.response.data //this error object comes from the server when any validation comes fail
      })
    );
};

// Login - Get User Token
export const loginUser = (userData, history) => dispatch => {
  dispatch(setuserloginLoadingStart()); //here we dispatch function called setuserloginLoadingStart() which will set the loading state True before it actually does the request
  axios
    .post("/p/admin/firebase/authenticate/token", userData)
    .then(async res => {
      // Save to localStorage
      const token = res.data.token;
      // console.log("our token response recieved!!")
      // console.log(token)

      let issuccess = false;
      var fctoken;

      await fire
        .auth()
        .signInWithCustomToken(token)
        .then(u => {
          // alert("Firebase Login Custom Token Success")

          fctoken = u.user.toJSON().stsTokenManager.accessToken;

          // console.log("custom token firebase res data below: ")
          // console.log(fctoken)

          // Set fctoken to local storage
          localStorage.setItem("jwtToken", fctoken);
          // Set token to Auth header
          setAuthToken(fctoken); //in token the current user data contain

          issuccess = true;

          // console.log("issuccess value is : "+issuccess)
          //  window.location.href = `dashboard`;
        })
        .catch(error => {
          dispatch(setuserloginLoadingStop());
          dispatch(sendFlashMessage(error.message, "alert-danger"));
          setTimeout(() => {
            dispatch(clearcurrentFlashMessage());
          }, 2000);

          issuccess = false;
          console.log(error);
          alert(error.message);
        });

      if (issuccess) {
        axios
          .get("/api/v1/councils/me")
          .then(async res => {
            let loggedinuserdata = res.data;
            let councilId = res.data.id;

            let councillogoImg = await axios.get(
              `/p/councils/${councilId}/logo`
            );
            localStorage.setItem(
              "councillogo",
              councillogoImg.data.userProperties.logo
            );

            localStorage.setItem("councilId", res.data.id);

            /*let loggedinuserdata={
        id:res.data.id,
        username:res.data.username,
        email:res.data.email,
        logo:res.data.logo
      }
      */

            // Set councilId to local storage
            dispatch(setuserloginLoadingStop());
            dispatch(
              sendFlashMessage("Login Successfully !!", "alert-success")
            );
            setTimeout(() => {
              dispatch(clearcurrentFlashMessage());
            }, 2000);

            // Set current user
            dispatch(setCurrentUser(loggedinuserdata)); //here we dispatch the seperated function (setCurrentUser=> its declare at bottom of this file )with current user decoded data
          })
          .then(res => history.push("/dashboard"))
          .catch(err => {
            dispatch(setuserloginLoadingStop());

            dispatch(
              sendFlashMessage(
                "There was an unexpected error while logging in.",
                "alert-danger"
              )
            );
            setTimeout(() => {
              dispatch(clearcurrentFlashMessage());
            }, 2000);

            console.log("get council me by token error is : " + err);
          });
      }
    })
    .catch(err => {
      dispatch(setuserloginLoadingStop());

      if (err.response.status === 401) {
        dispatch(
          sendFlashMessage(
            "Your credentials were not recoginized. Please try again",
            "alert-danger"
          )
        );
        setTimeout(() => {
          dispatch(clearcurrentFlashMessage());
        }, 2000);

        //  alert("Your credentials were not recoginized. Please try again")
      } else {
        dispatch(
          sendFlashMessage(
            "There was an unexpected error while logging in.",
            "alert-danger"
          )
        );
        setTimeout(() => {
          dispatch(clearcurrentFlashMessage());
        }, 2000);

        //alert('There was an unexpected error while logging in.')
      }
      console.log("login error is : " + err);
    });
};

// Login loading START
export const setuserloginLoadingStart = () => {
  return {
    type: GET_USER_LOGIN_LOADING_START //here we dont send payload only lets the reducer know GET_USER_LOGIN_LOADING_START is loading
  };
};
// Login loading STOP
export const setuserloginLoadingStop = () => {
  return {
    type: GET_USER_LOGIN_LOADING_STOP //here we dont send payload only lets the reducer know GET_USER_LOGIN_LOADING_STOP is loading
  };
};

// Set logged in user
export const setCurrentUser = userdata => {
  return {
    //here we dispatch the reducer
    type: SET_CURRENT_USER,
    payload: userdata //the decoded contains the actual user data from the token
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  let councilId = localStorage.getItem("councilId");
  let jwtToken = localStorage.getItem("jwtToken");
  //you are already logged out, if there is no token
  if (!jwtToken) {
    return;
  }

  console.log("logged id council id is : " + councilId);
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove councilId from localStorage
  localStorage.removeItem("councilId");
  // Remove councillogo from localStorage
  localStorage.removeItem("councillogo");

  // Remove auth header for future requests
  setAuthToken(false); //here we remove the X-Authorization token so at every request HAS NO Token after logout

  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({})); //in redux store isAuthenticated is set to false after logout

  if (councilId) {
    window.location.href = `login?councilId=${councilId}`;
  } else {
    window.location.href = "/login";
  }
  //history.push(`login?councilId=${councilId}`);
};

export const forgotpassUser = userData => dispatch => {
  dispatch(setforgetpasswordLoadingStart()); //here we dispatch function called setforgetpasswordLoadingStart() which will set the loading state True before it actually does the request
  axios
    .post("/api/users/forgotpassword", userData)
    .then(() => {
      dispatch(setforgetpasswordLoadingStop()); //here we dispatch function called setforgetpasswordLoadingStop() which will set the loading state True before it actually does the request
      dispatch(
        sendFlashMessage(
          "Check Your Email To Reset New Password !!",
          "alert-success"
        )
      );
    })
    .catch(err => {
      // console.log(err.response.data)
      dispatch(setforgetpasswordLoadingStop()); //here we dispatch function called setforgetpasswordLoadingStop() which will set the loading state True before it actually does the request
      dispatch(
        sendFlashMessage(err.response.data.message, err.response.data.className)
      );
    });
};

// FORGOT PASSWORD loading START
export const setforgetpasswordLoadingStart = () => {
  return {
    type: GET_FORGOT_PASSWORD_LOADING_START //here we dont send payload only lets the reducer know GET_FORGOT_PASSWORD_LOADING_START is loading
  };
};
// FORGOT PASSWORD STOP
export const setforgetpasswordLoadingStop = () => {
  return {
    type: GET_FORGOT_PASSWORD_LOADING_STOP //here we dont send payload only lets the reducer know GET_FORGOT_PASSWORD_LOADING_STOP is loading
  };
};

export const resetpassword = token => dispatch => {
  axios
    .get(`/api/users/resetpassword/${token}`)
    .then(res =>
      dispatch({
        type: SET_CURRENT_USER_RESET_PASS_DATA,
        payload: res.data
      })
    )
    .catch(err =>
      // console.log(err.response.data)
      dispatch(
        sendFlashMessage(err.response.data.message, err.response.data.className)
      )
    );
};

export const saveResetPassword = (userresetpassData, history) => dispatch => {
  dispatch(setforgetpasswordLoadingStart()); //here we dispatch function called setforgetpasswordLoadingStart() which will set the loading state True before it actually does the request
  axios
    .put("/api/users/saveresetpassword/", userresetpassData)
    .then(res => history.push("/login"))
    .then(res =>
      dispatch({
        type: SET_CURRENT_USER_RESET_PASS_DATA,
        payload: {}
      })
    )
    .then(() => {
      dispatch(setforgetpasswordLoadingStop()); //here we dispatch function called setforgetpasswordLoadingStop() which will set the loading state True before it actually does the request
      alert("Your Password reset successfully !!");
    })
    .catch(err => {
      dispatch(setforgetpasswordLoadingStop()); //here we dispatch function called setforgetpasswordLoadingStop() which will set the loading state True before it actually does the request
      // console.log(err.response.data)
      dispatch(
        sendFlashMessage(err.response.data.message, err.response.data.className)
      );
    });
};

export const updateUserInfo = (userData, history) => dispatch => {
  axios
    .post("/api/users/updateuserdata/", userData)

    .then(res => {
      //console.log(res.data);
      dispatch(setCurrentUser(res.data));
      dispatch(
        sendFlashMessage(
          "User Data is Updated Successfully !!",
          "alert-success"
        )
      );
    })
    //.then(alert("Product Stock is Update Successfully !!"))
    .then(res => history.push("/personal-account-setting"))
    .catch(err => {
      //console.log(err.response.data);

      dispatch(
        sendFlashMessage(err.response.data.message, err.response.data.className)
      );
    });
};

export const changedUserPassword = (
  userchangepassData,
  history
) => dispatch => {
  dispatch(setchangedpasswordLoadingStart()); //here we dispatch function called setchangedpasswordLoadingStart() which will set the loading state True before it actually does the request
  axios
    .post("/api/users/changeduserpass", userchangepassData)
    .then(() => {
      dispatch(logoutUser()); //here we logged out user if he changed pass success
      dispatch(setchangedpasswordLoadingStop()); //here we dispatch function called setchangedpasswordLoadingStop() which will set the loading state False after it actually does the request
      /* dispatch(
        sendFlashMessage(
          "Your current password is changed successfully!!",
          "alert-success"
        )
      );*/
      alert("Your current password is changed successfully!!");
    })
    .then(res => history.push("/login"))
    .catch(err => {
      // console.log(err);
      dispatch(setchangedpasswordLoadingStop()); //here we dispatch function called setchangedpasswordLoadingStop() which will set the loading state False after it actually does the request

      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
      dispatch(
        sendFlashMessage(err.response.data.message, err.response.data.className)
      );
    });
};

// CHANGED PASSWORD loading START
export const setchangedpasswordLoadingStart = () => {
  return {
    type: GET_CHANGED_PASSWORD_LOADING_START //here we dont send payload only lets the reducer know GET_WAREHOUSE_TRANSFER_PROD_STOCKS_LOADING is loading
  };
};
// CHANGED PASSWORD STOP
export const setchangedpasswordLoadingStop = () => {
  return {
    type: GET_CHANGED_PASSWORD_LOADING_STOP //here we dont send payload only lets the reducer know GET_WAREHOUSE_TRANSFER_PROD_STOCKS_LOADING_STOP is loading
  };
};
